.navigation {
  width: 100%;
  background: $background-color;
  min-height: 6rem;

  &.navigation--home {
    min-height: 0;
    height: 0px;

    @include media-breakpoint-up('sm') {
      height: initial;
    }
  }
}

.nav-links {
  position: fixed;
  z-index: 10;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: $background-color;

  font-size: 1.25rem;

  transition: transform .25s cubic-bezier(.65, 0, .076, 1);
  transform: translateX(100%);

  li {
    margin: 0.5rem 0;
  }

  @include media-breakpoint-up('md') {
    position: relative;

    padding: $nav-padding;

    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    font-size: 1rem;
    transform: none;

    li {
      margin: 0;
    }
  }
}

.nav-links.open {
  transform: translateX(0);
}

li.home-nav-link {
  margin-right: auto;

  .nav-link {
    display: inline-block;
    margin: 0;
    line-height: 0;
  }

  @include media-breakpoint-down('sm') {
    display: none;
  }
}

.nav-link {
  position: relative;

  margin: 0 0.33rem;

  font-weight: normal;
  font-size: 1.125rem;
  color: $text-color;
  text-transform: lowercase;
  text-decoration: none;
  outline: none;

  @include media-breakpoint-up('sm') {
    margin: 0 0.75rem;
  }

  &.active {
    border-bottom: 2px solid rgba($text-color, 0.7);
  }

  &:focus {
    outline: none;
    font-weight: normal;
  }
}

/* Menu toggle */
.menu-toggle {
  position: absolute;
  z-index: 20;

  top: 2rem;
  right: 1.5rem;

  background: none;
  border: none;

  svg {
    fill: $headings-color;
  }

  @include media-breakpoint-up('md') {
    display: none;
  }
}

.mobile-logo {
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;

  @include media-breakpoint-up('md') {
    display: none;
  }
}
