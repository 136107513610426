main.about-page {
  .about-page__images img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .about-page__text {
    h1 {
      margin-bottom: 1rem;
    }

    @include media-breakpoint-down('md') {
      padding-left: 2rem;
    }

    @include media-breakpoint-down('sm') {
      padding-left: 15px;
    }
  }
}
