main.portfolio {
  p:last-child {
    margin-bottom: 2 * $spacer;
  }

  .sub-nav {
    margin-bottom: 1rem;
  }

  .nav-link:first-child {
    margin-left: 0;
  }
}

.portfolio-list {
  margin: -0.5rem;

  @include media-breakpoint-down('sm') {
    margin: 0;
  }
}

.portfolio-list__gutter-sizer {
  width: 1rem;
}

.portfolio-list__sizer {
  width: 33.3333333%;

  @include media-breakpoint-down('md') {
    width: 50%;
  }

  @include media-breakpoint-down('sm') {
    width: 100%;
  }
}

.portfolio-list__item {
  display: flex;
  float: left;

  width: 33.3333333%;
  padding: .5rem;

  @include media-breakpoint-down('md') {
    width: 50%;
  }

  @include media-breakpoint-down('sm') {
    width: 100%;
    padding: 0;
    margin-bottom: 1rem;
  }

  a {
    flex: 1 1 auto;
    display: inline-block;

    overflow: hidden;

    outline: none;
  }

  img {
    display: block;
    width: 100%;
    height: auto;

    backface-visibility: hidden;

    transform: scale(1);
    transition: opacity 0.45s, transform 0.35s;
  }

  figcaption {
    position: absolute;
    z-index: 3;

    top: 50%;
    width: 100%;
    transform: translateY(-50%);

    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.25em;

    transition: opacity 0.45s;
    opacity: 0;
  }

  &:hover {
    img {
      transform: scale(1.1);
      opacity: 0.1
    }

    figcaption {
      opacity: 1;
    }
  }
}

// Portfolio item
main.portfolio-item {
  .swiper-container {
    position: relative;
    margin-bottom: 10px;
    line-height: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-size: 50%;
  }
}

.gallery-top {
  .swiper-slide {
    cursor: grab;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.gallery-thumbs {
  height: 110px;
  margin-bottom: 2 * $spacer;
}

.gallery-thumbs .swiper-slide {
  width: 80px;
  opacity: 0.4;
  cursor: pointer;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.portfolio-item__see-more,
.portfolio-item__content {
  margin-bottom: 3rem;

  @include media-breakpoint-up('sm') {
    margin-bottom: 4rem;
  }
}

.portfolio-item__text {
  padding-top: 2rem;
  padding-left: 15px;

  @include media-breakpoint-up('md') {
    padding-top: 0;
    padding-left: 3rem;
  }
}

.portfolio-item__see-more {
  text-align: center;
}
