.lang-switcher {
  padding: 0;

  display: flex;
  flex-direction: row;

  line-height: 1.6;
  border-top: 1px solid $footer-background;

  @include media-breakpoint-up('sm') {
    border-top: none;
    border-left: 1px solid $footer-background;
  }
}

.lang-switcher .nav-link {
  text-transform: uppercase;
}
