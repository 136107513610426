main.home {
  padding: 0;

  header {
    position: absolute;
    z-index: 3;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      @include media-breakpoint-down('md') {
        width: 200px;
        height: auto;
      }

      @include media-breakpoint-down('sm') {
        width: 175px;
        height: auto;
      }
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .swiper-slide > img {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}

