.footer {
  background: $footer-background;
  border-bottom: 0.5rem solid $headings-color;
  padding: 3em 0;

  h3 {
    text-transform: lowercase;
  }

  a {
    font-weight: normal;
  }
}

.footer-col {
  @include media-breakpoint-down('md') {
    padding-bottom: 2rem;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  line-height: 1;

  a {
    margin-right: 1.5rem;
  }

  svg {
    fill: rgba($headings-color, 0.7);
  }
}
