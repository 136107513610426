@font-face {
  font-family: "Alien League";
  font-display: swap;
  src: local("Alien League"), url("./../fonts/alienleague.woff") format("woff"),
    url("./../fonts/alienleague.svg"), url("./../fonts/alienleague.ttf");
}

// Brand
$text-color: #370617 !default;
$background-color: #edf6f9 !default;

// Spacers
$spacer: 1rem !default;

// Typography
$font-family: "Karla", sans-serif;
$font-size-base: 16px !default;
$font-size-sm: 14px !default;

// Headings
$headings-margin-bottom: 1 * $spacer !default;
$headings-font-family: "Alien League", sans-serif !default;
$headings-font-weight: 800 !default;
$headings-color: #370617 !default;

// Grid
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$container-max-widths: (
  xs: 400px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

// Navigation
$nav-padding: 1rem 0;

// Footer
$footer-background: darken($background-color, 2.5%);
