.snipcart {
  /* === Global === */
  /* Default */
  --color-default: #{$text-color};
  --borderColor-default: #{$background-color};
  --bgColor-default: #{$background-color};

  /* Alt */
  --color-alt: #{$text-color};
  --bgColor-alt: #{$background-color};

  /* Modal */
  --bgColor-modal: #{$background-color};
  --bgColor-modalVeil: #{$background-color};

  /* Shadows */
  --shadow-default: none;

  /* === Button Primary === */
  /* Default */
  --color-buttonPrimary: #fff;
  --borderColor-buttonPrimary: #{rgba($text-color, 0.8)};
  --bgColor-buttonPrimary: #{rgba($text-color, 0.8)};

  /* Hover */
  --color-buttonPrimary-hover: #fff;
  --borderColor-buttonPrimary-hover: #{$headings-color};
  --bgColor-buttonPrimary-hover: #{$headings-color};
  --shadow-buttonPrimary-hover: none;

  /* Active */
  --color-buttonPrimary-active: #fff;
  --borderColor-buttonPrimary-active: #{$headings-color};
  --bgColor-buttonPrimary-active: #{$headings-color};
  --shadow-buttonPrimary-active: none;

  /* Focus */
  --color-buttonPrimary-focus: #fff;
  --borderColor-buttonPrimary-focus: #{$headings-color};
  --bgColor-buttonPrimary-focus: #{$headings-color};
  --shadow-buttonPrimary-focus: none;

  /* === Button Secondary === */
  /* Default */
  --color-buttonSecondary: #{darken($background-color, 50)};
  --borderColor-buttonSecondary: transparent;
  --bgColor-buttonSecondary: transparent;

  /* Hover */
  --color-buttonSecondary-hover: #{darken($background-color, 50)};
  --borderColor-buttonSecondary-hover: #{darken($background-color, 50)};
  --bgColor-buttonSecondary-hover: transparent;
  --shadow-buttonSecondary-hover: none;

  /* Active */
  --color-buttonSecondary-active: #{darken($background-color, 50)};
  --borderColor-buttonSecondary-active: #{darken($background-color, 50)};
  --bgColor-buttonSecondary-active: transparent;
  --shadow-buttonSecondary-active: none;

  /* Focus */
  --color-buttonSecondary-focus: #{darken($background-color, 50)};
  --borderColor-buttonSecondary-focus: #{darken($background-color, 50)};
  --bgColor-buttonSecondary-focus: transparent;
  --shadow-buttonSecondary-focus: none;

  /* === Links === */
  /* Default */
  --color-link: #{darken($background-color, 50)};
  --borderColor-link: #{darken($background-color, 50)};

  /* Hover */
  --color-link-hover: #{darken($background-color, 50)};
  --borderColor-link-hover: #{darken($background-color, 50)};

  /* Active */
  --color-link-active: #{darken($background-color, 50)};
  --borderColor-link-active: #{darken($background-color, 50)};

  /* Focus */
  --color-link-focus: #{darken($background-color, 50)};
  --borderColor-link-focus: #{darken($background-color, 50)};

  /* === INPUT === */
  --borderColor-input-hover: #{darken($background-color, 20)};
  --borderColor-input-focus: #{darken($background-color, 20)};
  --borderColor-input-checked: #{darken($background-color, 20)};

  --color-inputLabel: #{$text-color};
  --color-inputLabel-hover: #{$text-color};
  --color-inputLabel-focus: #{$text-color};
}

.product-buttongroup {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.snipcart-add-item {
  display: inline-block;
  padding: 0.75rem;

  color: #fff;
  font-size: 1rem;
  font-family: $font-family;
  text-transform: uppercase;

  border-radius: 0.25rem;
  background: rgba($text-color, 0.8);
  border: 0;
  outline: 0;
  cursor: pointer;

  &:hover {
    background: $headings-color;
  }
}

.product-quantity {
  width: 5rem;
  padding: 0.5rem;
  margin-right: 1rem;
  border-radius: 0.25rem;
  border: 0;
  outline: 0;
}

.product-option {
  margin-bottom: 0.5rem;
}

.product-option__title {
  margin: 0 0 0.25rem 0;
  line-height: 1;
  font-size: 1.25rem;
}

.product-option__items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.product-option__item {
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.125rem 0.5rem;
  background-color: darken($background-color, 10);
  border-radius: 4px;
  border: 1px solid darken($background-color, 10);
  font-size: 1rem;
  cursor: pointer;

  &.selected {
    border-color: $text-color;
  }
}

.snipcart-checkout {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 0.25rem;
    fill: $text-color;
  }
}

.product-price {
  margin-bottom: 1rem;
  font-family: $headings-font-family;
  font-size: 1.5rem;
  font-weight: bold;
}
