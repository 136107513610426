html {
  height: 100%;
  font-size: $font-size-base;

  @include media-breakpoint-down('sm') {
    font-size: $font-size-sm;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  background: $background-color;
}

body, p {
  font-family: $font-family;
  color: $text-color;
  line-height: 1.5;
  font-size: 1.125rem;
  word-wrap: break-word;
}

main {
  position: relative;
  flex: 1 0 auto;
  padding: 3rem 0;
}

a,
a:link
a:visited {
  font-weight: 700;
  color: $headings-color;
  text-decoration: none;
}

p a,
p a:link,
p a:visited {
  border-bottom: 1px solid $headings-color;
}

a:active:focus,
a:focus:active,
a:active {
  outline: 0;
}

hr {
  margin: 2rem 0;
  text-align: center;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #bcbcbc;
    margin: 0 0.4em;
  }
}

address {
  font-style: normal;
}
