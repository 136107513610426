.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
}

label {
  display: inline-block;
  margin-bottom: .5 * $spacer;

  font-weight: normal;
}

form {
  width: 100%;

  @include media-breakpoint-up('md') {
    width: 75%;
  }
}

form,
input {
  display: block;
  font-family: $font-family;
}

input[type="email"] {
  display: block;
  flex: 1 1 auto;
  padding: 0.5rem;

  border-bottom: 2px solid rgba($text-color, 0.8);
  background: unset;
  outline: none;

  &:focus {
    border-bottom-color: $headings-color;
  }
}

input[type="submit"] {
  width: auto;
  margin-top: 1rem;

  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  color: #fff;
  text-transform: uppercase;
  background: rgba($text-color, 0.8);
  cursor: pointer;

  &:hover {
    background: $headings-color;
  }
}
