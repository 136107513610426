h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
}

h1, .h1 { font-size: 2.5rem; }
h2, .h2 { font-size: 1.75rem; }
h3, .h3 { font-size: 1.75rem; }
h4, .h4 { font-size: 1.5rem; }
h5, .h5 { font-size: 1.25rem; }
h6, .h6 { font-size: 1rem; }

p {
  margin-bottom: $spacer;
}

strong {
  color: $headings-color;
  font-weight: bold;
}
