.container {
  @include make-container();
  @include make-container-max-widths();
}

.container-fluid {
  @include make-container();
}

.container.no-gutter {
  padding-right: 0;
  padding-left: 0;
}

.row {
  @include make-row();
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@include make-grid-columns();
