.swiper-slide-caption {
  position: absolute;
  z-index: 2;
  padding: 1rem;

  bottom: 5rem;
  left: 5rem;
  background: $text-color;
  background: rgba($text-color, 0.7);

  @include media-breakpoint-down('sm') {
    bottom: 1rem;
    left: 1rem;
  }
}

.swiper-slide-caption__title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #fff;
  font-size: 1.75rem;
  font-weight: normal;
  padding-right: 1rem;
  letter-spacing: 0.05rem;
  transition: padding 150ms ease;

  svg {
    position: relative;
    left: 1rem;
    transition: left 150ms ease;
    fill: #fff;
  }

  &:hover {

    svg {
      left: 1.75rem;
    }
  }
}

.swiper-pagination-bullet {
  width: 0.25rem;
  height: 3rem;

  border-radius: 0;
  background: $headings-color;
}

.swiper-button-next,
.swiper-button-prev {
  color: $headings-color;
}
